import React from 'react'
import AddArtwork from './AddArtwork'

const AddProduct = () => {
  return (
    <>
       <AddArtwork/>
    </>
  )
}

export default AddProduct