import React from 'react'
import '../Styles/Artworks.css'  
import Product from '../components/product/Product';
 
const Artist =()=> {
 
    return (
        <section> 
        <Product/> 
         </section>
    ) 
}
 

 

export default Artist