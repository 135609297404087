// import React from 'react'
// import '../Styles/AboutUs.css'
// const AboutUs = () => {
//   return (
//     <div className="text-center">
//         <h1  >About Us</h1><br />
//         <p style={{textAlign: "justify"}} >Art-hub, the number one marketplace for art discoveries where rare and unique fine art meet NFT. We are committed to encourage people to be bold in reaching for their dreams and live a better life. Our platform will donate 10% of its profit to promote education, especially through the inspiration of art, in underprivileged regions in the world. 

// </p>
//     </div>
//   )
// }

// export default AboutUs


import React from 'react'

const AboutUs = () => {
  return (
    <div>AboutUs</div>
  )
}

export default AboutUs