export const slideData = [
    {
      image: "https://i.postimg.cc/y8WTxfqG/Demo-1.png",
      // heading: "Shoes Villa",
      // desc: "Up to 30% off on all onsale proucts.",
    },
    {
      image: "https://i.postimg.cc/XNH91ckw/body-Image-3.png",
      // heading: "Shoes Villa",
      // desc: "Up to 30% off on all onsale proucts.",
    },
    {
      image: "https://i.postimg.cc/0jDKc3Gn/body-Image-2.png",
      // heading: "Women Fashion",
      // desc: "Up to 30% off on all onsale proucts.",
    },
    
    {
      image: "https://img.freepik.com/free-photo/stylish-scandinavian-living-room-with-design-mint-sofa-furnitures-mock-up-poster-map-plants-eleg_1258-152175.jpg?w=1380&t=st=1690659646~exp=1690660246~hmac=21c98d58aeee43c8a55ee54577b00dfc6f8bef680a02426fce1ede9c52e45675",
      // heading: "Awesome Gadgets",
      // desc: "Up to 30% off on all onsale proucts.",
    },
    {
      image: "https://i.postimg.cc/XNLbyx06/Demo-2.png",
      // heading: "Awesome Gadgets",
      // desc: "Up to 30% off on all onsale proucts.",
    },
  ];
  