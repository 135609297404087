export const sliderData = [
  {
    image: "https://i.postimg.cc/vTQpCpfY/1.png",
    // heading: "Shoes Villa",
    // desc: "Up to 30% off on all onsale proucts.",
  },
  {
    // https://i.postimg.cc/NF1PL2KT/Hindi-Art-for-Busniness-Leaders.jpg"
     
    image: "https://i.postimg.cc/NF1PL2KT/Hindi-Art-for-Busniness-Leaders.jpg",
    // heading: "Women Fashion",
    // desc: "Up to 30% off on all onsale proucts.",
  },
  
  {
    image: "https://i.postimg.cc/Hnhv9r2X/62.jpg",
    // heading: "Awesome Gadgets",
    // desc: "Up to 30% off on all onsale proucts.",
  },
];